import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Container, Nav, NavDropdown, Row, Tab } from 'react-bootstrap';
import '../../scss/patientinfo/_patientInfo.scss';
import InitialConsultation from './InitialConsultation';
import InsuranceFinancing from './InsuranceFinancing';
import OralHygieneInstructions from './OralHygieneInstructions';
import PostopInstructions from './PostopInstructions';

class PatientInfo extends Component {
  render() {
    const { patientInfoData } = this.props;
    return (
      <>
        <section className="patient-info-title h-100">
          <Container fluid className="patient-info-containter">
            <Row>
              <Col md={12} className="text-center patient-info-sectionTitle">
                <h1>Patient Info</h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="patient-info-content h-100">
          <Container fluid className="patient-info-containter my-5">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="initialConsultation"
            >
              <Row>
                <Col md={3} className="mt-2">
                  <Nav variant="pills" className="flex-column">
                    <div className="d-none d-md-table">
                      <Nav.Item className="patient-info-nav text-center">
                        <Nav.Link eventKey="initialConsultation">
                          {
                            patientInfoData[0].patientInfo.initialConsultation
                              .title
                          }
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="patient-info-nav text-center">
                        <Nav.Link eventKey="insuranceFinancing">
                          {
                            patientInfoData[0].patientInfo.insuranceFinancing
                              .title
                          }
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="patient-info-nav text-center">
                        <Nav.Link eventKey="postopInstructions">
                          {
                            patientInfoData[0].patientInfo.postopInstructions
                              .title
                          }
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="patient-info-nav text-center">
                        <Nav.Link eventKey="oralHygieneInstructions">
                          {
                            patientInfoData[0].patientInfo
                              .oralHygieneInstructions.title
                          }
                        </Nav.Link>
                      </Nav.Item>
                    </div>
                    <div className="d-block d-md-none">
                      <NavDropdown
                        title="Services"
                        className="patient-info-dropdown w-100"
                      >
                        <NavDropdown.Item eventKey="initialConsultation">
                          {
                            patientInfoData[0].patientInfo.initialConsultation
                              .title
                          }
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="insuranceFinancing">
                          {
                            patientInfoData[0].patientInfo.insuranceFinancing
                              .title
                          }
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="postopInstructions">
                          {
                            patientInfoData[0].patientInfo.postopInstructions
                              .title
                          }
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="oralHygieneInstructions">
                          {
                            patientInfoData[0].patientInfo
                              .oralHygieneInstructions.title
                          }
                        </NavDropdown.Item>
                      </NavDropdown>
                    </div>
                  </Nav>
                </Col>
                <Col md={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="initialConsultation">
                      <InitialConsultation />
                    </Tab.Pane>
                    <Tab.Pane eventKey="insuranceFinancing">
                      <InsuranceFinancing />
                    </Tab.Pane>
                    <Tab.Pane eventKey="postopInstructions">
                      <PostopInstructions />
                    </Tab.Pane>
                    <Tab.Pane eventKey="oralHygieneInstructions">
                      <OralHygieneInstructions />
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </section>
      </>
    );
  }
}

// eslint-disable-next-line react/display-name
export default () => (
  <StaticQuery
    query={graphql`
      query PatientInfoQuery {
        allPatientinfoJson {
          nodes {
            patientInfo {
              initialConsultation {
                title
              }
              insuranceFinancing {
                title
              }
              postopInstructions {
                title
              }
              oralHygieneInstructions {
                title
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <PatientInfo patientInfoData={data.allPatientinfoJson.nodes} />
    )}
  />
);

PatientInfo.propTypes = {
  patientInfoData: PropTypes.array.isRequired,
};
