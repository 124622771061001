import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../scss/patientinfo/_info.scss';

class InitialConsultation extends Component {
  render() {
    const { patientInfoData } = this.props;
    return (
      <>
        <section className="info-content h-100">
          <Container fluid className="patient-containter">
            <Row>
              <Col md={12} className="text-left info-patientTitle mt-3">
                <h2 className="text-center">
                  {patientInfoData[0].patientInfo.initialConsultation.title}
                </h2>
                <p className="mt-4">
                  {
                    patientInfoData[0].patientInfo.initialConsultation
                      .discriptionOne
                  }
                </p>
                <p>
                  {
                    patientInfoData[0].patientInfo.initialConsultation
                      .discriptionTwo
                  }
                </p>
                <p>
                  {
                    patientInfoData[0].patientInfo.initialConsultation
                      .discriptionThree
                  }
                </p>
                <ul>
                  <li>
                    {
                      patientInfoData[0].patientInfo.initialConsultation
                        .bulletOne
                    }
                  </li>
                  <li>
                    {
                      patientInfoData[0].patientInfo.initialConsultation
                        .bulletTwo
                    }
                  </li>
                  <li>
                    {
                      patientInfoData[0].patientInfo.initialConsultation
                        .bulletThree
                    }
                  </li>
                </ul>

                <p>
                  <b>
                    {patientInfoData[0].patientInfo.initialConsultation.boldOne}
                  </b>
                  {
                    patientInfoData[0].patientInfo.initialConsultation
                      .discriptionFour
                  }
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

// eslint-disable-next-line react/display-name
export default () => (
  <StaticQuery
    query={graphql`
      query InitialConsultationQuery {
        allPatientinfoJson {
          nodes {
            patientInfo {
              initialConsultation {
                title
                discriptionOne
                discriptionTwo
                discriptionThree
                bulletOne
                bulletTwo
                bulletThree
                boldOne
                discriptionFour
                subtitleOne
                discriptionFive
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <InitialConsultation patientInfoData={data.allPatientinfoJson.nodes} />
    )}
  />
);

InitialConsultation.propTypes = {
  patientInfoData: PropTypes.array.isRequired,
};
