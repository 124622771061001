import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../scss/patientinfo/_info.scss';

class PostopInstructions extends Component {
  render() {
    const { patientInfoData } = this.props;
    return (
      <>
        <section className="info-content h-100">
          <Container fluid className="patient-containter">
            <Row>
              <Col md={12} className="text-left info-patientTitle mt-3">
                <h2 className="text-center">
                  {patientInfoData[0].patientInfo.postopInstructions.title}
                </h2>
                <p className="mt-4">
                  {
                    patientInfoData[0].patientInfo.postopInstructions
                      .discriptionOne
                  }
                </p>
                <ol>
                  <li className="pb-2">
                    <b>
                      {
                        patientInfoData[0].patientInfo.postopInstructions
                          .bulletOneBold
                      }
                    </b>
                    {
                      patientInfoData[0].patientInfo.postopInstructions
                        .bulletOne
                    }
                  </li>
                  <li className="pb-2">
                    <b>
                      {
                        patientInfoData[0].patientInfo.postopInstructions
                          .bulletTwoBold
                      }
                    </b>
                    {
                      patientInfoData[0].patientInfo.postopInstructions
                        .bulletTwo
                    }
                  </li>
                  <li className="pb-2">
                    <b>
                      {
                        patientInfoData[0].patientInfo.postopInstructions
                          .bulletThreeBold
                      }
                    </b>
                    {
                      patientInfoData[0].patientInfo.postopInstructions
                        .bulletThree
                    }
                  </li>
                  <li className="pb-2">
                    <b>
                      {
                        patientInfoData[0].patientInfo.postopInstructions
                          .bulletFourBold
                      }
                    </b>
                    {
                      patientInfoData[0].patientInfo.postopInstructions
                        .bulletFour
                    }
                  </li>
                  <li className="pb-2">
                    <b>
                      {
                        patientInfoData[0].patientInfo.postopInstructions
                          .bulletSixBold
                      }
                    </b>
                    {
                      patientInfoData[0].patientInfo.postopInstructions
                        .bulletSix
                    }
                  </li>
                  <li className="pb-2">
                    <b>
                      {
                        patientInfoData[0].patientInfo.postopInstructions
                          .bulletSevenBold
                      }
                    </b>
                    {
                      patientInfoData[0].patientInfo.postopInstructions
                        .bulletSeven
                    }
                  </li>
                  <li className="pb-2">
                    <b>
                      {
                        patientInfoData[0].patientInfo.postopInstructions
                          .bulletEightBold
                      }
                    </b>
                    {
                      patientInfoData[0].patientInfo.postopInstructions
                        .bulletEight
                    }
                  </li>
                  <li className="pb-2">
                    <b>
                      {
                        patientInfoData[0].patientInfo.postopInstructions
                          .bulletNineBold
                      }
                    </b>
                    {
                      patientInfoData[0].patientInfo.postopInstructions
                        .bulletNine
                    }
                  </li>
                  <li className="pb-2">
                    <b>
                      {
                        patientInfoData[0].patientInfo.postopInstructions
                          .bulletTenBold
                      }
                    </b>
                    {
                      patientInfoData[0].patientInfo.postopInstructions
                        .bulletTen
                    }
                  </li>
                  <li className="pb-2">
                    <b>
                      {
                        patientInfoData[0].patientInfo.postopInstructions
                          .bulletElevenBold
                      }
                    </b>
                    {
                      patientInfoData[0].patientInfo.postopInstructions
                        .bulletEleven
                    }
                  </li>
                </ol>

                <p>
                  <b>
                    {patientInfoData[0].patientInfo.postopInstructions.boldOne}
                  </b>
                </p>
              </Col>
              <Col md={12} className="text-center">
                <a
                  href={
                    patientInfoData[0].patientInfo.postopInstructions
                      .postopInstructionsLink
                  }
                  className="info-button"
                  target="_blank"
                  rel="noreferrer"
                >
                  {
                    patientInfoData[0].patientInfo.postopInstructions
                      .postopInstructionsText
                  }
                </a>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

// eslint-disable-next-line react/display-name
export default () => (
  <StaticQuery
    query={graphql`
      query PostopInstructionsQuery {
        allPatientinfoJson {
          nodes {
            patientInfo {
              postopInstructions {
                title
                discriptionOne
                bulletOneBold
                bulletOne
                bulletTwoBold
                bulletTwo
                bulletThreeBold
                bulletThree
                bulletFourBold
                bulletFour
                bulletFiveBold
                bulletFive
                bulletSixBold
                bulletSix
                bulletSevenBold
                bulletSeven
                bulletEightBold
                bulletEight
                bulletNineBold
                bulletNine
                bulletTenBold
                bulletTen
                bulletElevenBold
                bulletEleven
                boldOne
                postopInstructionsLink
                postopInstructionsText
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <PostopInstructions patientInfoData={data.allPatientinfoJson.nodes} />
    )}
  />
);

PostopInstructions.propTypes = {
  patientInfoData: PropTypes.array.isRequired,
};
