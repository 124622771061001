import React from 'react';
import Layout from '../layouts/layout';
import SEO from '../components/seo';
import '../scss/_index.scss';
import PatientInfo from '../components/PatientInfo/PatientInfo';

const PatientInfoTeamPage = () => (
  <Layout>
    <SEO title="Patient Info" />
    <PatientInfo />
  </Layout>
);

export default PatientInfoTeamPage;
