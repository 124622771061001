import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../scss/patientinfo/_info.scss';
import {
  FaMoneyBill,
  FaMoneyCheck,
  FaCcMastercard,
  FaCcVisa,
  FaCcAmex,
  FaCcDiscover,
} from 'react-icons/fa';

class InsuranceFinancing extends Component {
  render() {
    const { patientInfoData } = this.props;
    return (
      <>
        <section className="info-content h-100">
          <Container fluid className="info-containter">
            <Row>
              <Col md={12} className="text-left info-patientTitle mt-3">
                <h2 className="text-center">
                  {patientInfoData[0].patientInfo.insuranceFinancing.title}
                </h2>
                <p className="mt-4">
                  {
                    patientInfoData[0].patientInfo.insuranceFinancing
                      .discriptionOne
                  }
                </p>
                <p>
                  {
                    patientInfoData[0].patientInfo.insuranceFinancing
                      .discriptionTwo
                  }
                </p>
                <p>
                  {
                    patientInfoData[0].patientInfo.insuranceFinancing
                      .discriptionThree
                  }
                  <b>
                    {patientInfoData[0].patientInfo.insuranceFinancing.boldOne}
                  </b>
                </p>
                <p>
                  {
                    patientInfoData[0].patientInfo.insuranceFinancing
                      .discriptionFour
                  }
                </p>
                <p>
                  <b>
                    {patientInfoData[0].patientInfo.insuranceFinancing.boldTwo}
                  </b>
                  {
                    patientInfoData[0].patientInfo.insuranceFinancing
                      .discriptionFive
                  }
                  <b>
                    {
                      patientInfoData[0].patientInfo.insuranceFinancing
                        .boldThree
                    }
                  </b>
                  {/* {
                    patientInfoData[0].patientInfo.insuranceFinancing
                      .discriptionSix
                  } */}
                  <b>
                    {patientInfoData[0].patientInfo.insuranceFinancing.boldFour}
                  </b>
                  {
                    patientInfoData[0].patientInfo.insuranceFinancing
                      .discriptionSeven
                  }
                </p>
                <h3 className="info-servicesSubtitle py-2">
                  {
                    patientInfoData[0].patientInfo.insuranceFinancing
                      .subtitleOne
                  }
                </h3>
                <p>
                  {
                    patientInfoData[0].patientInfo.insuranceFinancing
                      .discriptionEight
                  }
                </p>
                <p>
                  {
                    patientInfoData[0].patientInfo.insuranceFinancing
                      .discriptionNine
                  }
                </p>
                <p className="info-icons">
                  <FaMoneyBill /> <FaMoneyCheck /> <FaCcMastercard />{' '}
                  <FaCcVisa /> <FaCcAmex /> <FaCcDiscover />
                </p>
                <p>
                  {
                    patientInfoData[0].patientInfo.insuranceFinancing
                      .discriptionTen
                  }
                  <b>
                    {patientInfoData[0].patientInfo.insuranceFinancing.boldFive}
                  </b>
                  {
                    patientInfoData[0].patientInfo.insuranceFinancing
                      .discriptionEleven
                  }
                  <b>
                    {patientInfoData[0].patientInfo.insuranceFinancing.boldSix}
                  </b>
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

// eslint-disable-next-line react/display-name
export default () => (
  <StaticQuery
    query={graphql`
      query InsuranceFinancingQuery {
        allPatientinfoJson {
          nodes {
            patientInfo {
              insuranceFinancing {
                title
                discriptionOne
                discriptionTwo
                discriptionThree
                boldOne
                discriptionFour
                boldTwo
                discriptionFive
                boldThree
                discriptionSix
                boldFour
                discriptionSeven
                subtitleOne
                discriptionEight
                discriptionNine
                discriptionTen
                boldFive
                discriptionEleven
                boldSix
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <InsuranceFinancing patientInfoData={data.allPatientinfoJson.nodes} />
    )}
  />
);

InsuranceFinancing.propTypes = {
  patientInfoData: PropTypes.array.isRequired,
};
